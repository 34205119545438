import axios from 'axios'
// const parser = require('ua-parser-js')
const accountIDStorageName = 'fb_account_id'
const accountTokenStorageName = 'fb_account_token'
const pageTokenStorageName = 'fb_page_token'
const pageIdStorageName = 'fb_page_id'

export default {
  state: {
    accountID: localStorage.getItem(accountIDStorageName),
    accountToken: localStorage.getItem(accountTokenStorageName),
    pageToken: localStorage.getItem(pageTokenStorageName),
    pageId: localStorage.getItem(pageIdStorageName),
  },
  getters: {
    getAccountID: state => {
      return state.accountID
    },
    getAccountToken: state => {
      return state.accountToken
    },
    getPageToken: state => {
      return state.pageToken
    },
    getPageId: state => {
      return state.pageId
    }
  },
  mutations: {
    setAccountID (state, accountID) {
      state.accountID = accountID
    },
    setAccountToken (state, accountToken) {
      state.accountToken = accountToken
    },
    setPageToken (state, pageToken) {
      state.pageToken = pageToken
    },
    setPageId (state, pageId) {
      state.pageId = pageId
    }
  },
  actions: {
    setPageId ({commit}, pageId){
      commit('setPageId', pageId)
      localStorage.setItem(pageIdStorageName, pageId)
    },
    setPageToken ({commit}, pageId){
      commit('setPageToken', pageId)
      localStorage.setItem(pageTokenStorageName, pageId)
    },
    getLoginStatus ({commit}) {
      return new Promise((resolve, reject) => {
        return window.FB.getLoginStatus(response => {
          if (response.authResponse) {
            commit('setAccountID', response.authResponse.userID)
            commit('setAccountToken', response.authResponse.accessToken)
            // resolve(response.authResponse)
            resolve({
              'user_id': response.authResponse.userID,
              'access_token': response.authResponse.accessToken
            })
          } else {
            reject('Silahkan login.')
          }
        })
      })
    },
    login ({ commit }) {
        return new Promise((resolve, reject) => {
            window.FB.getLoginStatus(response => {
                if (response.authResponse) {
                  // set state
                  commit('setAccountID', response.authResponse.userID)
                  commit('setAccountToken', response.authResponse.accessToken)
                  // save to local storage
                  // localStorage.setItem(accountIDStorageName, response.authResponse.userID)
                  // localStorage.setItem(accountTokenStorageName, response.authResponse.accessToken)
                  // return resolve
                  resolve({
                    'user_id': response.authResponse.userID,
                    'access_token': response.authResponse.accessToken
                  })
                } else {
                  window.FB.login((response) => {
                    if (response.authResponse) {
                      // set state
                      commit('setAccountID', response.authResponse.userID)
                      commit('setAccountToken', response.authResponse.accessToken)
                      // save to local storage
                      // localStorage.setItem(accountIDStorageName, response.authResponse.userID)
                      // localStorage.setItem(accountTokenStorageName, response.authResponse.accessToken)
                      // return resolve
                      resolve({
                        'user_id': response.authResponse.userID,
                        'access_token': response.authResponse.accessToken
                      })
                    } else {
                      reject('Gagal terhubung dengan facebook.')
                    }
                  }, {scope: 'public_profile,email,pages_show_list,pages_read_user_content,pages_read_engagement'})
                }
            })
        })
    },
    generateLongLiveUserToken ({ commit }, access_token) {
      return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_API_URL}facebook/long-lived-user-token`, {
          graph_version: 'v9.0',
          access_token: access_token
        }).then(resp => {
          // access token hanya di set utk long live token
          // commit('setAccountToken', resp.data.access_token)
          // localStorage.setItem(accountTokenStorageName, resp.data.access_token)
          resolve(resp)
        }).catch((error) => {
          reject(error)
        }) 
      })
    },
    logoutLongLivedToken ({ commit }, access_token){
      return new Promise((resolve, reject) => {
        return fetch(`${process.env.VUE_APP_API_URL}facebook/long-lived-user-token`, {
          method: 'DELETE',
          body: JSON.stringify({
            access_token: access_token
          })
        }).then(response => response.json()).then(data => resolve(data)).catch((error) => {
          reject(error)
        }) 
      }) 
    },
    logoutShortedLiveToken ({commit}){
      return new Promise((resolve) => {
        return window.FB.getLoginStatus(response => {
          if (response.authResponse) {
            window.FB.logout(function(response) {
              return resolve(response)
            })
          } else {
            return resolve()
          }
        })
      }) 
    },
    logout ({ dispatch, commit }, isLongLiveToken = false) {
        return new Promise((resolve) => {
          // if (isLongLiveToken === false) {
            commit('setAccountID', null)
            localStorage.removeItem(accountIDStorageName)
            commit('setAccountToken', null)
            localStorage.removeItem(accountTokenStorageName)
            commit('setPageId', null)
            localStorage.removeItem(pageIdStorageName)
            commit('setPageToken', null)
            localStorage.removeItem(pageTokenStorageName)
            // rapii lagi
            localStorage.removeItem('page_id')
            localStorage.removeItem('page_name')
            return resolve()
          // } else {
          //   return dispatch('logoutLongLivedToken').then(() => {
          //     dispatch('logoutShortedLiveToken', null).then(() => {
          //       commit('setAccountID', null)
          //       localStorage.removeItem(accountIDStorageName)
          //       commit('setAccountToken', null)
          //       localStorage.removeItem(accountTokenStorageName)
          //       commit('setPageId', null)
          //       localStorage.removeItem(pageIdStorageName)
          //       commit('setPageToken', null)
          //       localStorage.removeItem(pageTokenStorageName)
          //       return resolve()
          //     })
          //   })
          // }
        })
    }
  }
}
