import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

const NotFound = () => import(/* webpackChunkName: "NotFound" */ '@/pages/GeneralViews/NotFoundPage')
const Login = () => import(/* webpackChunkName: "Login" */ '@/pages/Auth/Login.vue')
const MemberInfo = () => import(/* webpackChunkName: "Login" */ '@/pages/Auth/MemberInfo.vue')
const Logout = () => import(/* webpackChunkName: "Logout" */ '@/pages/Auth/Logout.vue')
const MemberInfoLayout = () => import(/* webpackChunkName: "MemberInfoLayout" */ '@/pages/Layouts/MemberInfoLayout.vue')
const AuthLayout = () => import(/* webpackChunkName: "AuthLayout" */ '@/pages/Layouts/AuthLayout.vue')
const GeneralScreenDashboard = () => import(/* webpackChunkName: "GeneralScreenDashboard" */ '@/pages/Layouts/GeneralScreenDashboard.vue')
const EmptyContent = () => import(/* webpackChunkName: "GeneralScreenDashboard" */ '@/pages/Layouts/EmptyContent.vue')
const Home = () => import(/* webpackChunkName: "Home" */ '@/pages/Dashboard/Home.vue')
const SettingPassword = () => import(/* webpackChunkName: "Home" */ '@/pages/Settings/Password.vue')
const UserList = () => import(/* webpackChunkName: "User" */ '@/pages/User/List.vue')
const UserForm = () => import(/* webpackChunkName: "User" */ '@/pages/User/Form.vue')
const ShowUser = () => import(/* webpackChunkName: "User" */ '@/pages/User/Detail.vue')
const MemberList = () => import(/* webpackChunkName: "Member" */ '@/pages/Member/List.vue')
const MemberForm = () => import(/* webpackChunkName: "Member" */ '@/pages/Member/Form.vue')
const ShowMember = () => import(/* webpackChunkName: "Member" */ '@/pages/Member/Detail.vue')
const SignatureList = () => import(/* webpackChunkName: "Signature" */ '@/pages/Signature/List.vue')
const SignatureForm = () => import(/* webpackChunkName: "Signature" */ '@/pages/Signature/Form.vue')
const ShowSignature = () => import(/* webpackChunkName: "Signature" */ '@/pages/Signature/Detail.vue')
const WaitingList = () => import(/* webpackChunkName: "Member" */ '@/pages/Member/WaitingList.vue')

const routes = [
  {
    path: '/logout',
    component: Logout,
    meta: { requiresAuth: true }
  },
  {
    path: '/auth',
    redirect: '/auth/login',
    component: AuthLayout,
    children: [
      {
        path: 'login',
        name: 'Login Account',
        component: Login
      }
    ],
    meta: { requiresGuest: true }
  },
  {
    path: '/kta',
    redirect: '/',
    component: MemberInfoLayout,
    children: [
      {
        path: ':id',
        name: 'Cek Data Anggota',
        component: MemberInfo
      }
    ]
  },
  {
    path: '/',
    component: GeneralScreenDashboard,
    children: [
      {
        // path: 'index',
        path: '/',
        name: 'Home',
        redirect: 'dashboard',
        component: Home
      },
      {
        path: 'dashboard',
        name: 'Empty Content Order Group Container',
        component: EmptyContent,
        children: [
          {
            path: 'index',
            alias: '/',
            name: 'Beranda',
            component: Home
          },
        ]
      },
      {
        path: 'settings',
        name: 'Empty Content Setting Container',
        component: EmptyContent,
        children: [
          {
            path: 'password',
            name: 'Setting Password',
            component: SettingPassword
          }
        ]
      },
      {
        path: 'users',
        name: 'Empty Content Admin Container',
        component: EmptyContent,
        children: [
          {
            path: 'index',
            alias: '/',
            name: 'DAFTAR USER',
            component: UserList,
            meta: { role: 'Super Admin' }
          },
          {
            path: 'create',
            name: 'TAMBAH USER',
            component: UserForm
          },
          {
            path: ':id/edit',
            name: 'EDIT USER',
            component: UserForm
          },
          {
            path: ':id',
            name: 'DETAIL USER',
            component: ShowUser
          }
        ]
      },
      {
        path: 'members-waiting',
        name: 'Empty Content Anggota Diajukan Container',
        component: EmptyContent,
        children: [
          {
            path: 'index',
            alias: '/',
            name: 'Daftar Tunggu Anggota',
            component: WaitingList
          },,
          {
            path: ':id/edit',
            name: 'Edit Anggota Untuk Diajukan',
            component: MemberForm
          },
          {
            path: ':id',
            name: 'Detail Anggota Untuk Diajukan',
            component: ShowMember
          }
        ]
      },
      {
        path: 'members-create',
        name: 'Empty Content Member Container',
        component: EmptyContent,
        children: [
          {
            path: 'index',
            alias: '/',
            name: 'Tambah Anggota Baru',
            component: MemberForm
          },
        ]
      },
      {
        path: 'members',
        name: 'Empty Content Anggota Container',
        component: EmptyContent,
        children: [
          {
            path: 'index',
            alias: '/',
            name: 'Daftar Anggota',
            component: MemberList
          },
          {
            path: 'create',
            name: 'Tambah Anggota',
            component: MemberForm
          },
          {
            path: ':id/edit',
            name: 'Edit Anggota',
            component: MemberForm
          },
          {
            path: ':id',
            name: 'Detail Anggota',
            component: ShowMember
          }
        ]
      },
      {
        path: 'signatures',
        name: 'Empty Content Tanda Tangan Container',
        component: EmptyContent,
        children: [
          {
            path: 'index',
            alias: '/',
            name: 'Daftar Tanda Tangan',
            component: SignatureList
          },
          {
            path: 'create',
            name: 'Tambah Tanda Tangan',
            component: SignatureForm
          },
          {
            path: ':id/edit',
            name: 'Edit Tanda Tangan',
            component: SignatureForm
          },
          {
            path: ':id',
            name: 'Detail Tanda Tangan',
            component: ShowSignature
          }
        ]
      }
    ],
    meta: { requiresAuth: true }
  }, {
    path: '*', component: NotFound
  }
]

Vue.use(VueRouter)
const router = new VueRouter({
  routes, // short for router: router
  linkActiveClass: 'active'
})

if (store.getters['account/isLoggedIn']) {
  store.dispatch('account/saveTokens', { tokens: store.getters['account/tokens'], accountData: null, isLocalLogin: true })
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters['account/isLoggedIn']) {
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    if (store.getters['account/isLoggedIn']) {
      next({
        path: '/',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router